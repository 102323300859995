import { toast } from 'react-toastify';

import { fetch } from 'api/fetch';
import { getClient } from 'api/getClient';
import { ID, Profile } from 'api/models';
import t from 'core/helpers/t';

import { PROFILE_KEY, profile } from './profile';

export const usernameLogin = (body: { password: string; username: string }) => {
  const p = fetch<Profile>({ body, method: 'POST', url: '/auth/login' }).then(profile.queryFn);

  toast.promise(
    p,
    {
      error: t('Invalid login'),
      pending: t('Authenticating...'),
      success: t('Login successful'),
    },
    { toastId: 'loginForm' },
  );

  return p.catch(() => {});
};

export const logout = {
  mutationFn: () => {
    const promise = fetch<Profile>({ method: 'POST', url: '/auth/logout' })
      .then(() => null)
      .catch((e) => {
        if (e.code === 401 || e.code === 403) {
          // TODO: add lazy log here ...
          return null;
        }
        throw e;
      });

    toast.promise(
      promise,
      { pending: t('Logging-Out...'), success: t('Logged Out successfully') },
      { toastId: 'logout' },
    );

    return promise;
  },

  mutationKey: ['logout'],
  onSuccess: () => {
    getClient().setQueryData([PROFILE_KEY], null);
  },
};

export const emailSignUp = (body: { signUpEmail: string }) => {
  const p = fetch({
    body: { email: body.signUpEmail },
    method: 'POST',
    url: '/signup',
  });

  // TODO: enable dynamic texts
  toast.promise(p, {
    error: {
      render: ({ data }) => {
        return (
          <div>
            {t('Cannot create new account.')}
            <br />
            {/* @ts-ignore TODO: improve err handling*/}
            {data?.message || ''}
          </div>
        );
      },
    },
    pending: t('Creating account...'),
    success: t('Check your email for verification token.'),
  });

  return p;
};

export const forgotPassword = (body: { email: string }) => {
  return fetch({ body, method: 'POST', url: '/password/forgot' });
};

export const verifyResetRequest = (token: undefined | string) => ({
  queryFn: () =>
    token
      ? fetch<Profile>({ method: 'GET', url: `/password/verify/${token}` })
      : Promise.reject('Invalid reset request id'),
  queryKey: ['verifyResetRequest', token],
  retry: 0,
});

export const verifyInvitation = (invitation: ID, hash: string) => ({
  queryFn: () =>
    fetch<Profile>({ method: 'GET', url: `/signup/verify/${invitation}/${hash}` }).then(() => null),
  queryKey: ['verifyInvitation', invitation, hash],
  retry: 0,
});

export const resetPassword = (request: undefined | string) => ({
  mutationFn: (body: { password: string }) =>
    request
      ? fetch({ body, method: 'POST', url: `/password/reset/${request}` })
      : Promise.reject('Invalid reset request id'),
  mutationKey: ['resetPassword'],
});

export const verifyEmail = (invitation: undefined | string, token: undefined | string) => ({
  enabled: !!invitation && !!token,
  queryFn: () => {
    const p = fetch<null>({
      method: 'GET',
      url: `/signup/verify/${invitation}/${token}`,
    });

    toast.promise(p, {
      error: t('Cannot verify email.'),
      pending: t('Verifying email'),
      success: t('Email verified.'),
    });

    return p;
  },
  queryKey: ['verifyEmail'],
});

export const createAccount = (invitation: undefined | string, token: undefined | string) => ({
  mutationFn: (data: {
    firstName: string;
    lastName: string;
    password: string;
    timeZone: string;
  }) => {
    const { timeZone, ...body } = data;
    const p = fetch({
      body: { ...body, settings: { timeZone } },
      method: 'POST',
      url: `/signup/profile/${invitation}/${token}`,
    }).then(profile.queryFn);

    toast.promise(p, {
      error: t('Cannot create Account.'),
      pending: t('Creating Account'),
      success: t('Account created.'),
    });

    return p;
  },
  mutationKey: ['createAccount'],
});

export const googleLogin = (query: string, setUser: (p: Profile) => void) => ({
  onSuccess: (user: Profile) => {
    user && setUser(user);
  },
  queryFn: () =>
    fetch({ method: 'GET', url: `/auth/login/google/callback?${query}` }).then(profile.queryFn),
});
