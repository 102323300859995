import { forwardRef } from 'react';

import Props from './props';

const Link = forwardRef<HTMLAnchorElement, Props>(({ children, className, onClick, to }, ref) => {
  return (
    <a
      ref={ref}
      className={className}
      href={to}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          onClick();
        }
      }}
    >
      {children}
    </a>
  );
});

export default Link;
