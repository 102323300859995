import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import getVersion from 'app/helpers/getVersion';

import { getUser } from './index';

let initialized = false;

export const getLogger = () => {};

export default async function init() {
  if (import.meta.env.NODE_ENV !== 'production') {
    return;
  }
  const Sentry = await import('@sentry/react');
  const { BrowserTracing } = await import('@sentry/browser');
  const { CaptureConsole, HttpClient } = await import('@sentry/integrations');

  if (initialized) {
    return Sentry;
  }

  initialized = true;

  Sentry.init({
    beforeSend(event) {
      const user = getUser();

      const isApiError = event.contexts?.response?.status_code === 400;

      if (!isApiError && event.exception) {
        Sentry.showReportDialog({
          eventId: event.event_id,
          user: user ? { email: user.email, name: user.displayName } : undefined,
        });
      }

      return event;
    },
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENVIRONMENT,
    integrations: [
      new Sentry.Replay({
        networkDetailAllowUrls: [window.location.origin],
      }),
      new Sentry.BrowserProfilingIntegration(),
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new CaptureConsole({
        levels: ['error'],
      }),
      new HttpClient({
        failedRequestStatusCodes: [400, [404, 599]],
        failedRequestTargets: [/api\/.*/],
      }),
    ],
    release: `${window.location.host.split('.').reverse().join('-')}@${getVersion()}`,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.5,
    sendDefaultPii: true,
    tracePropagationTargets: [`${window.location.origin}`],
    tracesSampleRate: 1,
  });

  return Sentry;
}
