import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import Application from './Application';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container!);

window.t = (m: string, _options?: Record<string, string | number>) => m;

root.render(
  <StrictMode>
    <Application />
  </StrictMode>,
);

reportWebVitals();
