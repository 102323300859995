import { FC } from 'react';

import errorBanner from 'app/assets/error.svg';
import FullscreenLayout from 'app/layouts/FullScreenLayout';
import nav from 'app/nav';
import t from 'core/helpers/t';
import Text from 'ui/components/Text';

const ErrorFallback: FC = () => {
  return (
    <FullscreenLayout>
      <Text variant="h1">{t('Oops!')}</Text>
      <Text>
        {t('Looks like we have an error.')}
        <br />
        {t('Please try')} <a href={nav.home}>{t('Reload')}</a> {t('page.')}
      </Text>
      <img alt="banner" src={errorBanner} />
    </FullscreenLayout>
  );
};

export default ErrorFallback;
