import { QueryKey } from '@tanstack/react-query';

import { getClient } from 'api/getClient';
import { Base, ID } from 'api/models';

// uid + data
export type UpdateVariables<R> = { uuid: string } & Partial<R>;
// data + optional uuid = uuid ? PUT : POST
export type SaveVariables<R> = { uuid?: ID } & R;
// data
export type CreateVariables<R> = R;
// uuid
export type DeleteVariables = string;

export function onSuccess<E extends Base, R>(key: QueryKey, isParentKey?: boolean) {
  return function successCallback(
    data: undefined | E,
    variables: UpdateVariables<R> | CreateVariables<R> | DeleteVariables,
  ) {
    const update = (list: undefined | E[]): E[] => {
      const uuid =
        typeof variables === 'string'
          ? variables
          : // @ts-ignore TODO: fix
          'uuid' in variables
          ? variables.uuid
          : undefined;

      let updatedList = list || [];

      const isDelete = !!(uuid && !data);
      const isUpdate = !!(uuid && data);
      const isCreate = !!(data && !uuid);

      // delete
      if (isDelete) {
        updatedList = updatedList.filter((i) => i.uuid !== uuid);
      }
      // update
      else if (isUpdate) {
        const i = data ? (list || []).findIndex((i) => i.uuid === data.uuid) : -1;

        if (i > -1) {
          updatedList = [...updatedList];
          updatedList[i] = { ...data };
        }
      }

      // create
      else if (isCreate) {
        updatedList = updatedList.concat([{ ...data }]);
      } else {
        throw new Error('Invalid operation');
      }

      return updatedList;
    };

    const client = getClient();

    if (isParentKey) {
      const sets = client.getQueriesData<E>(key);
      sets.forEach(([key]) => {
        client.setQueryData(key, update);
      });
    } else {
      client.setQueryData(key, update);
    }
  };
}
