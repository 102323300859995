import { useQuery } from '@tanstack/react-query';
import { ComponentType, createContext, FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Profile } from 'api/models';
import { nav } from 'app';

import { profile } from './actions/profile';
import { useSetUser } from './hooks';

export const UserContext = createContext<Profile | undefined>(undefined);

interface Props extends PropsWithChildren {
  fallback?: ComponentType;
  wasLoggedIn?: boolean;
}

const UserProvider: FC<Props> = ({ children, fallback, wasLoggedIn }) => {
  const getProfile = useMemo(() => ({ ...profile, enabled: !!wasLoggedIn }), [wasLoggedIn]);
  const navigate = useNavigate();
  const setUser = useSetUser();

  const { data, isError, isLoading } = useQuery<Profile | undefined>(getProfile);
  const F = fallback;

  useEffect(() => {
    if (isError) {
      setUser(undefined);
      navigate(nav.login());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return (
    <UserContext.Provider value={data}>
      {wasLoggedIn && F && isLoading ? <F /> : children}
    </UserContext.Provider>
  );
};

export default UserProvider;
