import { useEffect, useState } from 'react';

const getHj = () => (window as any).hj;
let hasHotJar = !!getHj();
const watchers = new Set<(v: boolean) => void>();

const handler = {
  set(obj: any, prop: any, value: any) {
    if (prop === 'hj') {
      hasHotJar = !!value;
      Array.from(watchers).forEach((t) => t(hasHotJar));
    }
    hasHotJar = !!value;
    return Reflect.set(obj, prop, value);
  },
};

new Proxy(window, handler);

export default function useHasHotJar() {
  const [state, setState] = useState<boolean>((() => (hasHotJar = !!getHj()))());

  useEffect(() => {
    watchers.add(setState);

    return () => {
      watchers.delete(setState);
    };
  }, []);

  return state;
}

export function setHotJarUser(email?: string, name?: null | string, id?: string | number) {
  const hj = getHj();

  if (hj && id) {
    hj('identify', id, { email, name });
  }
}
