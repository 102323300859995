import { FC, useEffect, useRef } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useUser } from 'api';
import authNav from 'auth/nav';
import worklogNav from 'worklog/nav';

import { useActiveWorkspace, useAppState } from './containers/AppState';

const nav = {
  ...authNav,
  ...worklogNav,
  contact: '/contact',
  error: '/error-500',
  home: '/',
  notFound: '/not-found',
  privacy: '/privacy-policy',
  profile: '/profile',
  terms: '/terms-conditions',
  unauthorized: '/error-403',
};

let preAuthPath: string | undefined = undefined;

export default nav;

/**
 * Wrapper for Routes which requires Authenticated user
 */
export const RequireAuth: FC = () => {
  const { wasLoggedIn } = useAppState();
  const user = useUser();
  const userPromise = useRef<any>();

  useEffect(() => {
    if (user && wasLoggedIn) {
      userPromise.current && userPromise.current();
    }
  }, [user, wasLoggedIn]);

  // Wait until user is loaded
  if (wasLoggedIn && !user) {
    throw new Promise((res) => {
      userPromise.current = res;
    });
  }

  if (!user) {
    preAuthPath = window.location.href.replace(window.location.origin, '');
    return <Navigate to={nav.login()} />;
  }

  return <Outlet />;
};

export const AnonymOnly: FC = () => {
  const user = useUser();

  if (user) {
    return <Navigate to={getPreAuthPath() || nav.home} />;
  }

  return <Outlet />;
};

export const RequireWorkspace: FC = () => {
  const [uuid] = useActiveWorkspace();
  const { wasLoggedIn } = useAppState();
  const user = useUser();
  const r = useRef<any>();

  const workspaces = user?.workspaces || [];

  useEffect(() => {
    if (user && wasLoggedIn) {
      r.current && r.current();
    }
  }, [user, wasLoggedIn]);

  // Wait until user is loaded
  if (!wasLoggedIn) {
    throw new Promise((res) => {
      r.current = res;
    });
  }

  if (!uuid || !workspaces.find((w) => w.uuid === uuid)) {
    return <Navigate to={nav.selectWorkspace} />;
  }

  return <Outlet />;
};

export function getPreAuthPath() {
  return `${preAuthPath || ''}`;
}
