import classNames from 'classnames';
import { forwardRef } from 'react';

import Icon from 'ui/components/Icon';

import Button from '../Button';

import type Props from './props';

const sizeMap = {
  md: 20,
  sm: 18,
  xs: 12,
};

const IconButton = forwardRef<HTMLButtonElement, Omit<Props, 'leadingIcon' | 'trailingIcon'>>(
  ({ badge, badgeBlink, className, name, size = 'md', ...props }, ref) => {
    return (
      <Button
        ref={ref}
        rounded
        className={classNames(className, 'relative')}
        leadingIcon={<Icon className="absolute" name={name} size={sizeMap[size]} />}
        size={size}
        {...props}
      >
        {badge && (
          <span
            className={classNames('absolute top-1 right-1', `text-${badge}-500`, {
              'animate-blink': badgeBlink,
            })}
          >
            <Icon name="circle" size={11} />
          </span>
        )}
      </Button>
    );
  },
);

export type { Props };

export default IconButton;
