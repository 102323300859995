import { ErrorBoundary, withProfiler, withSentryReactRouterV6Routing } from '@sentry/react';
import { lazy, FC, Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import 'app/index.css'; // TODO: ?inline - Experiment
import ApiProvider from 'api/ApiProvider';
import Monitor from 'app/components/Monitor';
import { AppStateProvider } from 'app/containers/AppState';
import LoadingLayout from 'app/layouts/LoadingLayout';
import nav, { AnonymOnly, RequireAuth, RequireWorkspace } from 'app/nav';
import ErrorFallback from 'app/pages/ErrorFallback';
import DevPage from 'ui/pages/DevPage';

const LoginPage = lazy(() => import('auth/pages/LoginPage'));
const GoogleLoginPage = lazy(() => import('auth/pages/GoogleLoginPage'));
const CreateAccountPage = lazy(() => import('auth/pages/CreateAccountPage'));
const SignUpPage = lazy(() => import('auth/pages/SignUpPage'));
const ForgotPasswordPage = lazy(() => import('auth/pages/ForgotPasswordPage'));
const ResetPasswordPage = lazy(() => import('auth/pages/ResetPasswordPage'));

const EmailSentPage = lazy(() => import('auth/pages/EmailSentPage'));
const VerifyEmailPage = lazy(() => import('auth/pages/VerifyEmailPage'));

const LogoutPage = lazy(() => import('auth/pages/LogoutPage'));

const WorklogPage = lazy(() => import('worklog/pages/WorklogsPage'));
const SelectWorkspacePage = lazy(() => import('worklog/pages/SelectWorkspacePage'));

const HomePage = lazy(() => import('app/pages/HomePage'));
const ForbiddenPage = lazy(() => import('app/pages/ForbiddenPage'));
const NotFoundPage = lazy(() => import('app/pages/NotFoundPage'));
const PrivacyPage = lazy(() => import('app/pages/PrivacyPage'));

export const SentryRoutes = withSentryReactRouterV6Routing(Routes);

const Application: FC = () => {
  return (
    <>
      <BrowserRouter>
        <HelmetProvider>
          <ApiProvider>
            <Suspense fallback={<LoadingLayout />}>
              <AppStateProvider fallback={LoadingLayout}>
                <Monitor />
                <ErrorBoundary fallback={<ErrorFallback />}>
                  <SentryRoutes>
                    <Route element={<DevPage />} path="/dev" />

                    <Route element={<AnonymOnly />}>
                      <Route element={<LoginPage />} path={nav.login()} />
                      <Route element={<GoogleLoginPage />} path={nav.googleLogin} />
                      <Route element={<CreateAccountPage />} path={nav.signUpInvitation()} />
                      <Route element={<SignUpPage />} path={nav.signUp()} />
                      <Route element={<ForgotPasswordPage />} path={nav.forgotPwd()} />
                      <Route element={<ResetPasswordPage />} path={nav.resetPwd()} />
                    </Route>

                    <Route element={<EmailSentPage />} path={nav.emailSent} />
                    <Route element={<VerifyEmailPage />} path={nav.verifyEmail()} />

                    <Route element={<LogoutPage />} path={nav.logout} />

                    <Route element={<RequireAuth />}>
                      <Route index element={<HomePage />} path={nav.home} />
                      <Route element={<RequireWorkspace />} path={nav.workspace()}>
                        <Route element={<WorklogPage />} path={nav.worklogs()} />
                      </Route>
                      <Route element={<SelectWorkspacePage />} path={nav.selectWorkspace} />
                    </Route>

                    <Route element={<PrivacyPage />} path={nav.privacy} />
                    <Route element={<ForbiddenPage />} path={nav.unauthorized} />
                    <Route element={<ErrorFallback />} path={nav.error} />

                    <Route element={<NotFoundPage />} path="*" />
                  </SentryRoutes>

                  <ToastContainer autoClose={3000} position="bottom-right" />
                </ErrorBoundary>
              </AppStateProvider>
            </Suspense>
          </ApiProvider>
        </HelmetProvider>
      </BrowserRouter>
      <div className="relative" id="floating" style={{ zIndex: 9999 }} />
    </>
  );
};

export default withProfiler(Application);
