import classNames from 'classnames';
import { FC } from 'react';

import Props from './props';

const Text: FC<Props> = ({ as, children, className, variant = 'b1' }) => {
  const Tag = as || (variant && /h[1-6]/.test(variant) && (variant as 'h1')) || 'span';

  return (
    <Tag
      className={classNames(
        {
          'font-bold': variant.startsWith('h'),
          'leading-none': /h[1-6]/.test(variant),
          'text-base': variant === 'b1',
          'text-base font-bold': variant === 'b1b',
          'text-sm font-bold leading-tight': variant === 'b2b',
          'text-sm leading-tight': variant === 'b2',
          'text-uppercase text-xs text-neutral-700 uppercase': variant === 'overline',
          'text-xs': variant === 'b3',
        },
        {
          'text-3xl mb-4': variant === 'h1',
        },
        className,
      )}
    >
      {children}
    </Tag>
  );
};

export default Text;
