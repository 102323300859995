export default function t(msg: string, vars?: Record<string, any>) {
  let v = msg;

  vars &&
    Object.keys(vars).forEach((k) => {
      v = v.replace(`{${k}}`, vars[k]);
    });

  return v;
}
