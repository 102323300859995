import classNames from 'classnames';
import { FC, MouseEvent, MutableRefObject, PropsWithChildren, ReactNode, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

import Icon from 'ui/components/Icon';
import Popover from 'ui/components/Popover';

import IconButton from '../buttons/IconButton';

import Props from './props';

export type { Props };

const Field: FC<Props> = ({
  chevron,
  children,
  className,
  disabled,
  error,
  helperText,
  htmlFor,
  info,
  label,
  leadingIcon,
  loading,
  onClear,
  required,
  trailingIcon,
}) => {
  const labelRef = useRef<HTMLLabelElement>(null);

  const focus = (onClick?: () => void) => (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onClick ? onClick() : labelRef.current?.click();
  };

  return (
    <FieldWrapper
      error={!!error}
      helperText={helperText}
      htmlFor={htmlFor}
      info={info}
      label={label}
      labelRef={labelRef}
      required={required}
    >
      <div
        className={twMerge(
          classNames(
            'flex relative flex-row rounded-md border-solid border items-start overflow-hidden',
            {
              'bg-neutral-100 pointer-events-none': disabled,
              'border-error-500': error,
              'border-neutral-300 focus-within:border-primary-500': !error,
              'pl-3': !leadingIcon,
              'pr-3': !trailingIcon && !chevron && !loading,
            },
            className,
          ),
        )}
        style={{ paddingBottom: 1, paddingTop: 1 }}
      >
        {leadingIcon && (
          <IconButton
            className="text-neutral-400 hover:text-dark my-px"
            size="sm"
            variant="text"
            {...leadingIcon}
            onClick={focus(leadingIcon.onClick)}
          />
        )}
        {children}
        {/* Loading indicator */}
        {loading && <Icon className="h-8 mx-1" name="loading" />}
        {/* Clear icon - preferred only if has value */}
        <IconButton
          className={classNames('invisible text-neutral-400 my-1.5 bg-white', {
            'group-hover:visible': !disabled && !loading && onClear,
          })}
          name="clear"
          size="sm"
          style={{ margin: 1, marginLeft: '-2rem', marginRight: -8 }}
          title={t('Clear value')}
          variant="text"
          onClick={(e) => {
            e.preventDefault();
            onClear?.();
          }}
        />

        {/* End Icon */}
        {trailingIcon && !trailingIcon.popover && (
          <IconButton
            {...trailingIcon}
            className="text-neutral-400 hover:text-dark m-[1px]"
            size="sm"
            variant="text"
            onClick={focus(trailingIcon.onClick)}
          />
        )}
        {/* End icon with popover */}
        {trailingIcon && trailingIcon.popover && (
          <Popover
            component={IconButton}
            id="inputTrailingIcon"
            props={{
              className: 'text-neutral-400 hover:text-dark m-[1px]',
              name: trailingIcon.name,
              size: 'sm',
              variant: 'text',
            }}
            state={trailingIcon.popoverState}
          >
            {trailingIcon.popover}
          </Popover>
        )}
        {/* Dropdown chevron */}
        {chevron && (
          <IconButton
            className="my-1.5 mx-[1px]"
            name="arrow_drop_down"
            size="xs"
            variant="text"
            onClick={() => {
              focus();
              if (typeof chevron === 'function') {
                chevron();
              }
            }}
          />
        )}
      </div>
    </FieldWrapper>
  );
};

export default Field;

export const FieldWrapper: FC<
  {
    error?: boolean;
    helperText?: ReactNode;
    htmlFor: string;
    info?: ReactNode;
    label?: ReactNode;
    labelRef?: MutableRefObject<any>;
    required?: boolean;
  } & PropsWithChildren
> = ({ children, error, helperText, htmlFor, info, label, labelRef, required }) => {
  return (
    <div className={classNames('flex flex-col flex-nowrap group max-w-full w-full')}>
      {(info || label) && (
        <div className="mb-0 flex h-6 items-center">
          <label
            ref={labelRef}
            className={classNames('text-sm font-bold', { 'text-error-500': error })}
            htmlFor={htmlFor}
          >
            {label}
            {required ? <span className="text-error-500"> *</span> : null}
          </label>
          {info && (
            <Popover
              component={Icon}
              id="infoDropdown"
              props={{ className: 'text-neutral-500 ml-auto', name: 'info' }}
            >
              {info}
            </Popover>
          )}
        </div>
      )}

      {children}

      {helperText && (
        <div
          className={classNames('text-sm  mt-1', {
            'text-error-500': error,
            'text-neutral-500': !error,
          })}
        >
          {helperText}
        </div>
      )}
    </div>
  );
};
