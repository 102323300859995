import { ID } from 'api/models';

const nav = {
  /**
   * Display list of workspaces / add form
   */
  selectWorkspace: '/w',
  /**
   * /w/:workspace/worklogs
   */
  worklogs: (workspaceId: ID = ':workspaceId') => `/w/${workspaceId}/worklogs`,
  /**
   * /w/:workspace
   */
  workspace: (workspaceId: ID = ':workspaceId') => `/w/${workspaceId}`,
};

export default nav;
