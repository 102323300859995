import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';

import { getWorkLogs } from 'api/actions/worklogs';
import { Worklog } from 'api/models/Worklog';
import { useActiveWorkspace } from 'app/containers/AppState';

import { WorklogValidFormData } from './useWorklogSchema';

const S_KEY = 'worklogPrefill';

export function setWorklogPrefill(data: null | Partial<WorklogValidFormData>) {
  if (!data) {
    localStorage.removeItem(S_KEY);
    return;
  }

  const old = getWorklogPrefill();
  const prefill = { ...old, ...data };
  // copy old if new is undefined
  (Object.keys(data) as Array<keyof typeof data>).forEach((key) => {
    if (data[key] === undefined) {
      // @ts-ignore TODO: ...
      prefill[key] = old[key] || null;
    }
  });

  localStorage.setItem(S_KEY, JSON.stringify(prefill));
}

export function getWorklogPrefill(): Partial<WorklogValidFormData> {
  try {
    return JSON.parse(localStorage.getItem(S_KEY) || '{}');
  } catch (_e) {
    return {};
  }
}

/**
 * Initialize prefill value
 */
export async function useWorklogPrefill() {
  const [workspaceUuid] = useActiveWorkspace();
  const q = useMemo(
    (): UseQueryOptions<any, Error, Worklog[]> => ({
      ...getWorkLogs(workspaceUuid, {
        range: { end: DateTime.now().startOf('week'), start: DateTime.now().startOf('week') },
      }),
      enabled: false,
      onSuccess: (workLogs: undefined | Worklog[]) => {
        const last = (workLogs || []).pop();
        if (last) {
          setWorklogPrefill({ labels: last.labels?.map((l) => l.name), project: last.uuid });
        }
      },
      queryKey: ['lastWeekWorklogs'],
    }),
    [workspaceUuid],
  );

  const { refetch } = useQuery(q);

  useEffect(() => {
    if (!localStorage.getItem(S_KEY)) {
      refetch();
    }
  }, [refetch]);
}
