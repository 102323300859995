import classNames from 'classnames';
import { FC, PropsWithChildren, useState } from 'react';

import Button from 'ui/components/buttons/Button';
import IconButton from 'ui/components/buttons/IconButton';
import DateField from 'ui/components/DateField';
import Select from 'ui/components/Select';
import TextField from 'ui/components/TextField';

const DevPage: FC = () => {
  const [ta, sta] = useState<null | string>('');
  const [single, setSingle] = useState<null | number>(null);
  const [multi, setMulti] = useState<null | number[]>(null);

  return (
    <div className="bg-neutral-400 auto-rows-min w-screen h-screen grid  md:grid-cols-3 sm:grid-cols-2 lg:grid-cols-5 gap-4 p-4">
      <Card>
        <L>Sizes button</L>
        <Button>Button</Button>
        <Button size="sm">SM Button</Button>
        <Button size="xs">XS Button</Button>
      </Card>
      <Card>
        <L>Variants</L>
        <Button>Button</Button>
        <Button variant="primary">Primary</Button>
        <Button variant="outline">Outline</Button>
        <Button color="error" variant="primary">
          Error
        </Button>
      </Card>
      <Card>
        <L>Appearance</L>
        <Button rounded variant="primary">
          Rounded
        </Button>
        <Button fullWidth variant="outline">
          Full Outline
        </Button>
        <Button loading title="Loading..." variant="primary">
          Loading
        </Button>
        <Button disabled>Disabled</Button>
      </Card>
      <Card>
        <L>Icons</L>
        <Button leadingIcon="rotate-left" variant="primary">
          Leading
        </Button>
        <Button size="sm" trailingIcon="rotate-right">
          Trailing SM
        </Button>
        <Button color="success" leadingIcon="rotate-left" size="xs" trailingIcon="rotate-right">
          Both XS
        </Button>
        <Button color="error" leadingIcon="rotate-left" variant="primary">
          Error
        </Button>
      </Card>
      <Card>
        <L>Icon Button</L>
        <IconButton color="error" name="loading" variant="outline" />
        <IconButton color="error" name="4k" variant="default" />
        <IconButton color="error" name="4k" size="sm" variant="primary" />
        <IconButton color="success" name="4k" size="xs" variant="primary" />
      </Card>
      <Card className="col-span-2">
        <TextField
          chevron
          loading
          required
          info="Some additional info"
          label="Text input"
          trailingIcon={{ name: 'add', onClick: () => {} }}
          value={ta}
          onChange={sta}
        />

        <TextField
          disabled
          info="Some additional info"
          label="Text input"
          leadingIcon={{ name: 'add' }}
          trailingIcon={{ name: 'add' }}
          value={ta}
          onChange={sta}
        />

        <TextField
          multiline
          info="Some additional info"
          label="Text input"
          leadingIcon={{ name: 'add' }}
          trailingIcon={{ name: 'add' }}
          value={ta}
          onChange={sta}
        />

        <DateField required label="Date From" value={new Date()} />
      </Card>

      <Card className="col-span-2">
        <Select<number, false>
          isRequired
          label="Select"
          name="dropdown1"
          options={[
            { label: 'One', value: 1 },
            { label: 'Two', value: 2 },
          ]}
          value={single}
          onChange={setSingle}
        />

        <Select<number, true>
          isRequired
          multiple
          label="Select"
          name="dropdown1"
          options={[
            { label: 'One', value: 1 },
            { label: 'Two', value: 2 },
            { label: 'Three', value: 3 },
            { label: 'Four', value: 4 },
            { label: 'Five', value: 5 },
            { label: 'Six Six Six', value: 6 },
            { label: 'Seven Seven Seven', value: 7 },
            { label: 'Eight Eight Eight', value: 8 },
            { label: 'Nine Nine Nine', value: 9 },
            { label: 'Ten Ten Ten', value: 10 },
          ]}
          value={multi}
          onChange={setMulti}
        />
      </Card>
    </div>
  );
};

export default DevPage;

const Card: FC<PropsWithChildren & { className?: string }> = ({ children, className }) => {
  return (
    <div
      className={classNames(
        'grid grid-cols-2 gap-2 rounded-lg p-4 bg-white min-w-[10rem] gap-1 flex flex-col justify-items-center align-items-center',
        className,
      )}
    >
      {children}
    </div>
  );
};

const L: FC<PropsWithChildren> = ({ children }) => (
  <span className="col-span-2 flex text-xs uppercase">{children}</span>
);
