import { DateTime } from 'luxon';
import { FC } from 'react';

import IconButton from '../buttons/IconButton';

interface Props {
  month: DateTime;
  setMonth: (v: DateTime) => void;
}

const CalendarControls: FC<Props> = ({ month, setMonth }) => {
  return (
    <div className="flex flex-1 flex-row gap-1">
      <IconButton
        name="arrow_back"
        size="xs"
        title={t('Previous Year')}
        onClick={() => setMonth(month.minus({ year: 1 }))}
      />
      <IconButton
        name="keyboard_arrow_left"
        size="xs"
        title={t('Previous Month')}
        onClick={() => setMonth(month.minus({ month: 1 }))}
      />
      <span className="flex-1 text-center font-semibold">{month.toFormat('MMM yyyy')}</span>
      <IconButton
        name="keyboard_arrow_right"
        size="xs"
        title={t('Next Month')}
        onClick={() => setMonth(month.plus({ month: 1 }))}
      />
      <IconButton
        name="arrow_forward"
        size="xs"
        title={t('Next Year')}
        onClick={() => setMonth(month.plus({ year: 1 }))}
      />
    </div>
  );
};

export default CalendarControls;
