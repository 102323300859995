import classNames from 'classnames';
import { DateTime, Info } from 'luxon';
import { FC } from 'react';

import Props from './props';

const Calendar: FC<Props> = ({ className, month, onDateClick, selected }) => {
  const start = month.startOf('month').startOf('week');
  const end = month.endOf('month').endOf('week');

  const diff = end.diff(start, ['days']);

  const days = 'a'
    .repeat(Math.floor(diff.days + 1))
    .split('')
    .map((_, i) => start.plus({ days: i }));
  const title = 'a'
    .repeat(7)
    .split('')
    .map((_, i) => start.plus({ days: i }));

  const active: [DateTime, DateTime] | null = selected
    ? Array.isArray(selected)
      ? selected
      : [selected, selected]
    : null;

  return (
    <div className={classNames(className, 'grid grid-cols-7 text-xs w-56 bg-white')}>
      {title.map((t) => (
        <div className="py-2 text-center font-semibold" key={t.weekday}>
          {Info.weekdays('short')[(t.weekday + 6) % 7]}
        </div>
      ))}
      {days.map((d) => (
        <Day
          currentMonth={month.toFormat('MM.yyyy') === d.toFormat('MM.yyyy')}
          date={d}
          key={d.toISODate()}
          selected={active}
          onClick={onDateClick}
        />
      ))}
    </div>
  );
};

export default Calendar;

const Day: FC<{
  currentMonth: boolean;
  date: DateTime;
  onClick?: (date: DateTime) => void;
  selected?: null | [DateTime, DateTime];
}> = ({ currentMonth, date, onClick, selected }) => {
  const active =
    selected &&
    selected[0].startOf('day') <= date.startOf('day') &&
    selected[1].startOf('day') >= date.startOf('day');

  const firstActive = selected && selected[0].startOf('day').equals(date.startOf('day'));
  const lastActive = selected && selected[1].startOf('day').equals(date.startOf('day'));

  return (
    <div
      className={classNames('flex flex-none items-center justify-center w-auto', {
        'bg-primary-500 text-white': !!active,
        'rounded-l-full': firstActive,
        'rounded-r-full': lastActive,
        'text-neutral-300': !active && !currentMonth,
      })}
      data-date={date.toISODate()}
      role="cell"
      title={date.toLocaleString()}
    >
      <button
        className={classNames(
          'hover:bg-overlay h-8 w-8 rounded-full flex items-center justify-center cursor-pointer',
          {
            'border border-solid border-neutral-300':
              !active && date.toFormat('dd.MM.yyyy') === DateTime.now().toFormat('dd.MM.yyyy'),
            'border border-solid border-primary-500 text-neutral-900':
              active && date.toFormat('dd.MM.yyyy') === DateTime.now().toFormat('dd.MM.yyyy'),
          },
        )}
        onClick={onClick ? () => onClick(date) : undefined}
      >
        {date.day}
      </button>
    </div>
  );
};
