import classNames from 'classnames';
import { forwardRef } from 'react';

import Icon from '../../Icon';
import Tooltip from '../../Tooltip';

import Props from './props';
import classes from './style.module.css';

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      className,
      color,
      disabled,
      fullWidth,
      leadingIcon,
      loading,
      onClick,
      onDoubleClick,
      rounded,
      size = 'md',
      style,
      title,
      trailingIcon,
      type = 'button',
      variant = 'default',
      ...props
    },
    ref,
  ) => {
    const button = (
      <button
        {...props}
        ref={ref}
        className={classNames(
          'flex',
          classes.base,
          {
            [classes.primary]: variant === 'primary',
            [classes.outline]: variant === 'outline',
            [classes.defaultBtn]: variant === 'default',
            [classes.text]: variant === 'text',
            [classes.defaultError]: variant === 'default' && color === 'error',
            [classes.defaultPrimary]: variant === 'default' && color === 'primary',
            [classes.rounded]: rounded,
            [classes.sizeXs]: size === 'xs',
            [classes.sizeSm]: size === 'sm',
            [classes.sizeMd]: size === 'md',
            [classes.fullWidth]: fullWidth,
          },
          className,
        )}
        disabled={disabled}
        style={style}
        type={type}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
      >
        {leadingIcon &&
          (typeof leadingIcon === 'string' ? <Icon name={leadingIcon} /> : leadingIcon)}

        {children}

        {trailingIcon &&
          (typeof trailingIcon === 'string' ? <Icon name={trailingIcon} /> : trailingIcon)}

        {loading && (
          <Icon
            aria-busy="true"
            aria-hidden="false"
            aria-live="polite"
            className="loading indent-0 absolute mx-auto my-auto"
            name="loading"
          />
        )}
      </button>
    );

    return title ? <Tooltip content={title}>{button}</Tooltip> : button;
  },
);

export type { Props };

export default Button;
