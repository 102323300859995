import { FC } from 'react';

import loading from 'app/assets/loading.svg';
import t from 'core/helpers/t';

const LoadingLayout: FC = () => {
  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <img alt={t('Loading placeholder')} className="max-w-[10rem]" src={loading} />
    </div>
  );
};

export default LoadingLayout;
