import { FC, useCallback, useEffect, useRef } from 'react';

import { useUser } from 'api';
import type { Profile } from 'api/models';

import monitor from './monitor';
import { setHotJarUser } from './useHasHotJar';

let sharedUser: Profile | undefined = undefined;

export function getUser() {
  return sharedUser;
}

const Monitor: FC = () => {
  const user = useUser();
  sharedUser = user;
  const sentryRef = useRef<any>(null);

  const setUser = useCallback((user?: Profile) => {
    if (sentryRef.current) {
      sentryRef.current?.setUser(
        user ? { email: user.email || '', username: user.displayName || '' } : null,
      );
    }

    setHotJarUser(user?.email, user?.displayName, user?.uuid);
  }, []);

  useEffect(() => {
    (async () => {
      sentryRef.current = await monitor();
      setUser(user);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUser(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return null;
};

export default Monitor;
