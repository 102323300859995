import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  useDelayGroup,
  useDelayGroupContext,
  useMergeRefs,
  useId,
  useTransitionStyles,
  arrow,
  FloatingArrow,
} from '@floating-ui/react';
import type { Placement } from '@floating-ui/react';
import {
  FC,
  HTMLProps,
  ReactNode,
  cloneElement,
  createContext,
  forwardRef,
  isValidElement,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

interface TooltipOptions {
  initialOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
  placement?: Placement;
}

export function useTooltip({
  initialOpen = false,
  onOpenChange: setControlledOpen,
  open: controlledOpen,
  placement = 'bottom',
}: TooltipOptions = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
  const setOpen = setControlledOpen ?? setUncontrolledOpen;
  const open = controlledOpen ?? uncontrolledOpen;
  const { delay } = useDelayGroupContext();
  const arrowRef = useRef(null);

  const data = useFloating({
    middleware: [offset(8), flip(), shift(), arrow({ element: arrowRef })],
    onOpenChange: setOpen,
    open,
    placement,
    whileElementsMounted: autoUpdate,
  });

  const context = data.context;

  const hover = useHover(context, {
    delay,
    enabled: controlledOpen == null,
    move: false,
  });
  const focus = useFocus(context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return useMemo(
    () => ({
      arrowRef,
      open,
      setOpen,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data],
  );
}

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = createContext<ContextType>(null);

export const useTooltipState = () => {
  const context = useContext(TooltipContext);

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />');
  }

  return context;
};

const Tooltip: FC<{ children: ReactNode; content: ReactNode } & TooltipOptions> = ({
  children,
  content,
  ...options
}) => {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip(options);

  if (!content) {
    return children;
  }

  return (
    <TooltipContext.Provider value={tooltip}>
      <TooltipTrigger>{children}</TooltipTrigger>
      <TooltipContent arrowRef={tooltip.arrowRef} context={tooltip.context}>
        {content}
      </TooltipContent>
    </TooltipContext.Provider>
  );
};

export const TooltipTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement>>(
  function TooltipTrigger({ children, ...props }, propRef) {
    const state = useTooltipState();

    const childrenRef = (children as any).ref;
    const ref = useMergeRefs([state.refs.setReference, propRef, childrenRef]);

    if (!isValidElement(children)) {
      return null;
    }

    return cloneElement(
      children,
      state.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': state.open ? 'open' : 'closed',
      }),
    );
  },
);

export const TooltipContent = forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement> & { arrowRef: any; context: any }
>(function TooltipContent({ arrowRef, children, context, ...props }, propRef) {
  const state = useTooltipState();
  const id = useId();
  const { currentId, isInstantPhase } = useDelayGroupContext();
  const ref = useMergeRefs([state.refs.setFloating, propRef]);

  useDelayGroup(state.context, { id });

  const instantDuration = 0;
  const duration = 250;

  const { isMounted, styles } = useTransitionStyles(state.context, {
    duration: isInstantPhase
      ? {
          close: currentId === id ? duration : instantDuration,
          open: instantDuration,
        }
      : duration,
    initial: {
      opacity: 0,
    },
  });

  if (!isMounted) return null;

  return (
    <FloatingPortal>
      <div
        ref={ref}
        className="rounded-md bg-dark p-1 px-1.5 text-xs text-white"
        style={{
          ...state.floatingStyles,
          ...styles,
        }}
        {...state.getFloatingProps(props)}
      >
        <FloatingArrow ref={arrowRef} className="fill-dark drop-shadow-lg" context={context} />
        {children}
      </div>
    </FloatingPortal>
  );
});

export default Tooltip;
