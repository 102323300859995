import { DateTime } from 'luxon';
import { FC, useEffect, useMemo, useState } from 'react';

import Calendar from 'ui/components/Calendar';
import CalendarControls from 'ui/components/Calendar/CalendarControls';

import TextField from '../TextField';

import Props from './props';

const DateField: FC<Props> = ({ id, name, onChange, value, ...props }) => {
  const htmlId = id || `datePicker-${name || Math.random().toFixed(10)}`;
  const val = useMemo(() => (value ? DateTime.fromJSDate(value) : null), [value]);
  const [month, setMonth] = useState(val || DateTime.now());
  const popoverState = useState(false);

  useEffect(() => {
    setMonth((p) => (val && p.toISODate() !== val.toISODate() ? val : p));
  }, [val]);

  return (
    <TextField
      {...props}
      readOnly
      id={htmlId}
      trailingIcon={{
        name: 'date_range',
        popover: (
          <div className="flex flex-col p-3">
            <div className="flex flex-row">
              <CalendarControls month={month} setMonth={setMonth} />
            </div>
            <div className="flex flex-row gap-2">
              <Calendar
                className="flex-1"
                month={month}
                selected={val}
                onDateClick={(date) => {
                  onChange &&
                    onChange(val && val.toISODate() === date.toISODate() ? null : date.toJSDate());
                  popoverState[1](false);
                }}
              />
            </div>
          </div>
        ),
        popoverState,
      }}
      value={val ? val.toFormat('dd. MMM yyyy') : ''}
      onChange={() => {}}
      onFocus={() => popoverState[1](true)}
    />
  );
};

export default DateField;
