import getVersion from 'app/helpers/getVersion';

const app = import.meta.env.VITE_NAME;
const ver = getVersion();
const host = import.meta.env.VITE_API_PATH;

// CSRF
const CSRF_COOKIE_NAME = 'ct';
const CSRF_HEADER_NAME = 'x-csrf-token';
const ERR_INVALID_CSRF = 'EBADCSRFTOKEN';

// Global variables
// copy/resend CSRF token
let csrfToken: string | undefined = undefined;
// Prevent loop when retrying csrf error
let waitCsrf: undefined | Promise<boolean> = undefined;
// last invalid csrf = prevent refresh csrf loop
let lastInvalidCsrf: string | undefined = undefined;

interface ErrorResponse {
  code?: string; // some enum ??
  developer?: string;
  message: string;
}

export class ApiError extends Error {
  static readonly kind: 'ApiError';
  code: string;
  response: Response;
  data?: any;

  constructor(response: Response, data?: any) {
    const message = data?.message || response.statusText;
    super(message);
    this.message = message;
    this.code = data?.code || response.status;
    this.response = response;
    this.data = data;
  }
}

type In =
  | string
  | (Omit<RequestInit, 'body'> & { body?: any; headers?: Record<string, any>; url: string });

// TODO: improve fetch typings
export async function fetch<D extends Object | void | null = void>(
  input: In,
): Promise<undefined | D> {
  const inputs = typeof input === 'string' ? { method: 'GET', url: input } : input;

  const reqContentType =
    inputs.headers?.['content-type'] ||
    inputs.headers?.['Content-Type'] ||
    inputs.headers?.['Content-type'] ||
    inputs.headers?.['content-Type'];

  let json: any = '';

  try {
    // auto-magic content stringify when no content was set
    json =
      'body' in inputs && inputs.body && (!inputs.headers || !reqContentType)
        ? JSON.stringify(inputs.body)
        : null;
  } catch {
    json = null;
  }

  const headers = new Headers();

  headers.append('x-csrf-token', csrfToken || '');
  headers.append('x-request-platform', `${app}-${ver}`);

  const settings: Omit<RequestInit, 'headers'> & { headers?: Headers } = {
    ...inputs,
    body: json || inputs.body || null,
    headers,
  };

  if (settings.method !== 'GET' && !reqContentType && settings.headers) {
    settings.headers.append('content-type', 'application/json');
  }

  const response = await window.fetch(`${host}${inputs.url}`, settings);

  const contentType = (response.headers.get('content-type') || '').toLowerCase();

  // process CSRF
  csrf(response);

  let data: D | null | ErrorResponse | Blob = null;
  // Parse data
  if (contentType.includes('application/json') && response.json) {
    try {
      data = await response.json();
    } catch {
      data = null;
    }
  } else if (contentType === 'application/octet-stream') {
    const blob = await response.blob();
    const display = response.headers.get('content-disposition');

    if (display?.includes('attachment')) {
      const url = window.URL.createObjectURL(blob);
      const fileName = display
        .replace(/^.*filename=(.*)$/, '$1')
        .trim()
        .replaceAll('"', '');

      const element = document.createElement('a');
      element.setAttribute('href', url);
      element.setAttribute('download', fileName);
      document.body.appendChild(element);
      element.click();
      element.remove();
      window.URL.revokeObjectURL(url);
    }

    data = blob;
  }

  if (response.status === 401) {
    // getNavigate()('/logout');
    error(response, data);
  } else if (response.status === 403) {
    // refresh token if its csrf problem
    if (data && typeof data === 'object' && 'code' in data && data.code === ERR_INVALID_CSRF) {
      const canRetry = await refreshCsrf();
      if (canRetry && csrfToken) {
        return await fetch<D>(input);
      }
    }

    // getNavigate()('/logout');
    // throw error
    error(response, data);
  } else if (response.status >= 400) {
    // handle rest invalid data
    throw new ApiError(response, data);
  }

  if (csrfToken) {
    lastInvalidCsrf = undefined;
  }

  return data as D | undefined;
}

/**
 * Create standard error
 */
function error(response: Response, data?: any) {
  throw new ApiError(response, data);
}

/**
 * !!! SIDE EFFECTS !!!
 * Refresh CSRF token by hitting root api service
 */
function refreshCsrf(): Promise<boolean> {
  if (lastInvalidCsrf && lastInvalidCsrf === csrfToken) {
    return Promise.resolve(false);
  }

  // prevent invalid loop - remember last bad and skip if not changed
  lastInvalidCsrf = csrfToken;

  const promise =
    waitCsrf ||
    fetch<void>({ method: 'GET', url: '/' }).then(() => {
      waitCsrf = undefined;
      return !lastInvalidCsrf;
    });

  waitCsrf = promise;

  return promise;
}

/**
 * !!! SIDE EFFECTS !!!
 * Read csrf from response
 */
function csrf(response: Response): void {
  let token: string | undefined = undefined;
  // headers
  token = response.headers.get(CSRF_HEADER_NAME) || undefined;
  // cookie
  if (!token) {
    const cookie = response.headers.get('set-cookie') || document.cookie;

    const cookies = (cookie ? cookie.split(';') : [])
      .map((i) => i.trim())
      .reduce((acc, i) => {
        const set = i.split('=');
        return { ...acc, [set[0]]: set[1] };
      }, {} as Record<string, string>);

    token = cookies[CSRF_COOKIE_NAME];
  }

  if (lastInvalidCsrf !== token) {
    lastInvalidCsrf = undefined;
  }

  csrfToken = token || csrfToken;
}
