import { LocaleObject } from 'yup';

import t from 'core/helpers/t';

export default {
  mixed: {
    default: () => t('This field is invalid'),
    required: () => t('This field is required'),
  },
} as LocaleObject;
