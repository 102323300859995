import { MutationOptions } from '@tanstack/react-query';
import { Settings, DateTime } from 'luxon';
import { toast } from 'react-toastify';

import { fetch } from 'api/fetch';
import { getClient } from 'api/getClient';
import { Profile, ProfileRequest } from 'api/models';
import { Member } from 'api/models/Workspace';

import { imageUpload } from './imageUpload';

export const PROFILE_KEY = 'profile';

export const profile = {
  queryFn: () =>
    fetch<Profile>({ method: 'GET', url: '/profile' }).then((profile) => {
      const tz = profile?.settings.timeZone;
      let isValidTz = false;

      try {
        isValidTz = DateTime.now().setZone(tz).zoneName === tz;
      } catch (e) {}

      if (tz && isValidTz) {
        Settings.defaultZone = tz;
      }

      return profile;
    }),
  queryKey: [PROFILE_KEY],
  retry: 0,
};

export const editProfile: MutationOptions<Profile | undefined, Error, ProfileRequest> = {
  mutationFn: (body) => fetch<Profile>({ body, method: 'PUT', url: '/profile' }),
  mutationKey: [PROFILE_KEY],
  onSuccess: (data) => {
    data && getClient().setQueryData([PROFILE_KEY], () => data);
  },
};

export const changePassword = {
  mutationFn: (body: { newPassword: string; oldPassword: string }) => {
    const p = fetch<Profile>({ body, method: 'PUT', url: '/profile/password' });

    toast.promise(
      p,
      {
        error: t('Error changing password.'),
        pending: t('Changing password...'),
        success: t('Password changed!'),
      },
      { toastId: 'saveProfile' },
    );

    return p.catch(() => undefined);
  },
  mutationKey: ['changePassword'],
  retry: 0,
};

export const changeAvatar = {
  mutationFn: (file: null | File) =>
    imageUpload('/profile/avatar', file)
      .then(profile.queryFn)
      .then((profile) => {
        getClient().setQueryData([PROFILE_KEY], () => profile);
        profile?.workspaces?.forEach(({ uuid }) => {
          getClient().setQueryData([uuid, 'members'], (list: undefined | Member[]) =>
            list
              ? list.map((i) => (i.uuid === profile.uuid ? { ...i, avatar: profile.avatar } : i))
              : [],
          );
        });
      }),
  mutationKey: ['changeAvatar'],
  retry: 0,
};

export const deleteProfile = {
  mutationFn: (password: string) =>
    fetch<null>({ body: { password }, method: 'POST', url: '/profile/delete' }).then(() => null),
  mutationKey: ['deleteProfile'],
};
