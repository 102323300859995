import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Settings } from 'luxon';
import { useCallback, useContext } from 'react';

import { logout, usernameLogin } from 'api/actions/auth';
import { Profile } from 'api/models';
import { useSetAppState } from 'app/containers/AppState';
import { setWorklogPrefill } from 'worklog/containers/WorklogModal/worklogPrefill';

import { PROFILE_KEY } from './actions/profile';
import { getClient } from './getClient';
import { UserContext } from './UserProvider';

export const useUser = () => {
  return useContext(UserContext);
};

export const useLogin = () => {
  const queryClient = useQueryClient();
  const setState = useSetAppState();

  const setUser = useCallback(
    (data: Profile) => {
      queryClient.setQueryData([PROFILE_KEY], data);
      setState((s) => ({ ...s, wasLoggedIn: !!data }));
    },
    [queryClient, setState],
  );

  const m = useMutation({
    mutationFn: usernameLogin,
    mutationKey: ['usernameLogin'],
    onSuccess: (user) => {
      user && setUser(user);
    },
  });

  return useCallback(
    (username: string, password: string) =>
      m
        .mutateAsync({ password, username })
        .catch(() => {})
        .then(() => {}),
    [m],
  );
};

/**
 * Logout user
 */
export const useLogout = () => {
  const setState = useSetAppState();

  return useMutation({
    ...logout,
    onSettled: () => {
      setWorklogPrefill(null); // Clear on logout
      Settings.defaultZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      setState((s) => ({ ...s, activeWorkspace: undefined, wasLoggedIn: undefined }));
    },
  });
};

/**
 * Update current user
 */
export const useSetUser = () => {
  const setState = useSetAppState();

  return useCallback(
    (user: undefined | Profile) => {
      setWorklogPrefill(null); // Clear on login
      getClient().setQueryData([PROFILE_KEY], () => user);
      setState((s) => ({ ...s, wasLoggedIn: !!user }));
    },
    [setState],
  );
};
