import qs from 'qs';

const nav = {
  emailSent: '/email-sent',
  forgotPwd: (email?: string) => (email ? `/forgot?${qs.stringify({ email })}` : '/forgot'),
  googleLogin: '/login/google/callback',
  login: (email?: string) => (email ? `/login?${qs.stringify({ email })}` : '/login'),
  logout: '/logout',
  resetPwd: (token = ':token') => `/reset/${token}`,
  signUp: (email?: string) => (email ? `/sign-up?${qs.stringify({ email })}` : '/sign-up'),
  /**
   * Verify email invitation
   * @param {string} invitation Invitation uid
   * @param {string} token Verification token
   * @returns
   */
  signUpInvitation: (invitation: string = ':invitation', hash: string = ':hash') =>
    `/sign-up/${invitation}/${hash}`,
  verifyEmail: (invitation: string = ':invitation', hash: string = ':hash') =>
    `/verify/${invitation}/${hash}`,
};

export default nav;
