/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  FloatingArrow,
  FloatingPortal,
  arrow as arrowMiddleware,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
  offset as offsetMiddleware,
  useRole,
  flip,
} from '@floating-ui/react';
import { FC, useRef, useState } from 'react';

import Props from './props';

const Popover: FC<Props> = ({
  arrow = true,
  children,
  className,
  component,
  custom,
  initialOpen,
  offset = 8,
  placement = 'bottom-start',
  preventClose,
  props,
  state,
}) => {
  const innerState = useState(!!initialOpen);
  const arrowRef = useRef(null);
  const Component = component;

  const [isOpen, setIsOpen] = state || innerState;

  const { context, floatingStyles, refs } = useFloating({
    middleware: [
      offsetMiddleware(offset),
      arrowMiddleware({ element: arrowRef }),
      flip(),
      ...(custom || []),
    ],
    onOpenChange: (s, event, reason) => {
      (!preventClose || !!preventClose(event, reason)) && setIsOpen(s);
    },
    open: isOpen,
    placement,
    strategy: 'fixed',
  });

  const dismiss = useDismiss(context);
  const focus = useFocus(context);
  const click = useClick(context);
  const role = useRole(context, { role: 'tooltip' });

  const { getFloatingProps, getReferenceProps } = useInteractions([focus, role, click, dismiss]);

  return (
    <>
      <Component
        {...props}
        {...getReferenceProps()}
        ref={refs.setReference}
        onClick={() => setIsOpen(true)}
      />
      {isOpen && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            className={className || 'rounded-md bg-white p-1 text-sm drop-shadow-lg'}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            {...getFloatingProps}
            style={floatingStyles}
          >
            {arrow !== false && (
              <FloatingArrow
                ref={arrowRef}
                className="drop-shadow-lg"
                context={context}
                fill="white"
              />
            )}
            {children}
          </div>
        </FloatingPortal>
      )}
    </>
  );
};

export default Popover;
