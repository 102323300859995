import { FC, PropsWithChildren } from 'react';

import { nav } from 'app';
import logo from 'app/assets/logo.svg';
import t from 'core/helpers/t';
import Link from 'ui/components/Link';

const FullScreenLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="relative flex h-screen w-screen items-center justify-center bg-primary-50/20 py-20">
      <Link className="absolute left-8 top-8" to={nav.home}>
        <img alt={t('Timero')} className="w-9 max-w-full" src={logo} />
      </Link>
      <main className="flex w-4/5 max-w-[32rem] flex-col items-center">{children}</main>
    </div>
  );
};

export default FullScreenLayout;
