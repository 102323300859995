import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import ReactSelect from 'react-select';
import CreatableReactSelect from 'react-select/creatable';

import { FieldWrapper } from 'ui/components/Field';

import { defaultComponents } from './components';
import Props, { Option, Values } from './props';

function Select<V extends Values = Values, IsMulti extends boolean = false>({
  components,
  formatOptionLabel,
  isDisabled,
  isLoading,
  isRequired,
  multiple,
  name,
  onAdd,
  onChange,
  onInputChange,
  options,
  value,
  ...props
}: Props<V, IsMulti>) {
  const selectedValue = useMemo(
    () =>
      (options || []).filter((o) =>
        // @ts-ignore TODO: use generic ??
        multiple ? value?.includes?.(o.value) : o.value === value,
      ),
    [multiple, options, value],
  );

  const htmlFor = useMemo(() => `labels-${name || Math.random() * 1000000}`, [name]);

  const C = onAdd ? CreatableReactSelect : ReactSelect;

  const [customized, setCustomized] = useState({
    ...defaultComponents,
    ...(components || {}),
  });

  useEffect(() => setCustomized({ ...defaultComponents, ...(components || {}) }), [components]);

  return (
    <FieldWrapper htmlFor={htmlFor} {...props} required={isRequired}>
      <C<Option<V>, IsMulti>
        isClearable
        unstyled
        classNames={customClassNames}
        // @ts-ignore TODO: ...
        components={customized}
        formatOptionLabel={formatOptionLabel}
        inputId={htmlFor}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={multiple}
        name={name}
        options={options}
        value={selectedValue}
        onChange={(next) => {
          onChange(
            // @ts-ignore TODO: ...
            multiple ? (next?.length > 0 ? next.map((o) => o.value) : null) : next?.value || null,
          );
        }}
        onCreateOption={(n) => {
          if (onAdd) {
            onAdd(n).then((option) => {
              onChange(
                // @ts-ignore TODO: ...
                multiple ? [...value, option.value] : option.value,
              );
            });
          }
        }}
        onInputChange={onInputChange}
      />
    </FieldWrapper>
  );
}

export default Select;

export const customClassNames = {
  container: (state: any) =>
    classNames(
      'group border focus-within:border-primary-500 rounded border-neutral-300 shadow-none',
      { 'bg-neutral-100': state.isDisabled, 'pointer-events-none': state.isDisabled },
    ),
  control: () => 'min-h-8 pl-3 gap-2',
  menuList: () => 'mt-[1px] py-1 bg-white drop-shadow',
  multiValue: () =>
    'group/chip items-center text-base rounded px-1.5 hover:bg-neutral-300 h-6 text-neutral-700 bg-neutral-300/50',
  option: (state: any) =>
    classNames('px-2 py-1 text-base', {
      'bg-neutral-100': state.isFocused,
      'bg-neutral-200': state.isSelected,
    }),
  placeholder: () => 'text-base text-neutral-400',
  valueContainer: () => 'gap-1 my-1.5',
};
