import classNames from 'classnames';
import { forwardRef } from 'react';

import Tooltip from '../Tooltip';

import Props from './props';

import 'app/assets/icomoon/style.css';

/**
 * App Icon component
 * - available to click
 * - when its clickable it has some background on hover
 * - if its focused, its possible to use Arrow Down to activate
 */
const Icon = forwardRef<HTMLButtonElement | HTMLSpanElement, Props>(
  (
    { buttonType = 'button', className, color, name, onClick, size, style, title, ...props },
    ref,
  ) => {
    const C = onClick || props.onMouseDown || props.onTouchEnd ? 'button' : 'span';

    const icon = (
      <C
        onMouseDown={(e) => e.preventDefault()} // prevent focus on click
        {...props}
        ref={ref as any}
        className={classNames(
          'flex items-center justify-center leading-none rounded-full',
          `timero-${name}`,
          {
            'cursor-pointer': !!onClick,
            'loading animate-spin': name === 'loading',
            'text-error-500': color === 'error',
            'text-primary-500': color === 'primary',
            'text-success-500': color === 'success',
            'text-warning-500': color === 'warning',
          },
          className,
        )}
        style={{ color, fontSize: size, ...(style || {}) }}
        type={buttonType}
        onClick={onClick}
        onKeyDown={(e) => {
          if (onClick && e.code === 'ArrowDown') {
            e.currentTarget.blur(); // deactivate focus
            onClick(e as any); // trigger action with focused item and arrow down - dropdown
          }
          props.onMouseDown?.(e as any);
        }}
      />
    );

    return title ? <Tooltip content={title}>{icon}</Tooltip> : icon;
  },
);

export type { Props };

export default Icon;
