import {
  ComponentType,
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';

import { ID } from 'api/models';
import UserProvider from 'api/UserProvider';
import isUuid from 'core/helpers/isUuid';
export interface AppState {
  activeWorkspace?: ID;
  version: 1;
  wasLoggedIn?: boolean;
}

const initialState: AppState = { version: 1 };
const PERSISTANCE_KEY = 'appState';

const AppContext = createContext<AppState>(initialState);
// @ts-ignore TODO: type initial value
const SetContext = createContext<React.Dispatch<React.SetStateAction<AppState>>>();

interface Props extends PropsWithChildren {
  fallback?: ComponentType;
}

let navigateRef: NavigateFunction = () => {};

export const getNavigate = () => {
  return navigateRef;
};

export const AppStateProvider: FC<Props> = ({ children, fallback }) => {
  const initialState = useRef(getPersisted());
  const [state, set] = useState<AppState>(initialState.current);
  const navigate = useNavigate();

  useEffect(() => {
    navigateRef = navigate;
    return () => {
      navigateRef = () => {};
    };
  }, [navigate]);

  useEffect(() => {
    localStorage.setItem(PERSISTANCE_KEY, JSON.stringify(state));
  }, [state]);

  return (
    <SetContext.Provider value={set}>
      <AppContext.Provider value={state}>
        <UserProvider fallback={fallback} wasLoggedIn={initialState.current.wasLoggedIn}>
          {children}
        </UserProvider>
      </AppContext.Provider>
    </SetContext.Provider>
  );
};

export const useSetAppState = () => useContext(SetContext);

export const useAppState = () => useContext(AppContext);

export const useActiveWorkspace = (): [ID | undefined, (v: ID) => void] => {
  // const m = useMatch<any, '/w/:workspaceId'>();
  const { workspaceId: uuid } = useParams<{ workspaceId: string }>();
  const location = useLocation();
  const navigate = useNavigate();

  const set = useCallback(
    (uuid: ID) => {
      const url = /^\/w\/[a-f0-9-]{36}\//.test(location.pathname)
        ? location.pathname.replace(/^\/w\/[a-f0-9-]{36}\//, `/w/${uuid}/`)
        : `/w/${uuid}/`;

      navigate(url);
    },
    [location.pathname, navigate],
  );

  const workspaceId = isUuid(uuid || '') ? uuid : undefined;

  return useMemo(() => [workspaceId, set], [workspaceId, set]);
};

export const useSetActiveWorkspace = () => {
  const set = useSetAppState();
  return useCallback(
    (uuid: ID) => {
      set((s) => ({ ...s, activeWorkspace: uuid }));
    },
    [set],
  );
};

function getPersisted(): AppState {
  try {
    const state = JSON.parse(localStorage.getItem(PERSISTANCE_KEY) || '{}');
    if (state.version === initialState.version) {
      return state;
    }
  } catch {
    return initialState;
  }
  return initialState;
}
