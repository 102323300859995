import { UseMutationOptions, UseQueryOptions, useMutation } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import qs from 'qs';
import { useMemo } from 'react';
import { toast } from 'react-toastify';

import { fetch } from 'api';
import { ID } from 'api/models';
import { Worklog, WorklogRequest, WorklogStatus } from 'api/models/Worklog';
import t from 'core/helpers/t';
import { WorklogValidFormData } from 'worklog/containers/WorklogModal/useWorklogSchema';
import { setWorklogPrefill } from 'worklog/containers/WorklogModal/worklogPrefill';
import { Range } from 'worklog/pages/WorklogsPage';

import { CreateVariables, DeleteVariables, onSuccess, UpdateVariables } from './onSuccess';

export const WORKLOGS_STORE_KEY = 'worklogs';
export const WORKLOGS_EXPORT_STORE_KEY = 'worklogs_export_worklogs';
export const WORKLOGS_COUNT_STORE_KEY = 'worklogs_export_count';

type WorklogFilters = {
  includeTimers?: boolean;
  labels?: null | ID[];
  projects?: null | ID[];
  range: Range;
};

export const getWorkLogs = (
  workspaceUuid: ID | undefined,
  filters: WorklogFilters,
  refetchInterval?: number,
): UseQueryOptions<any, Error, Worklog[]> => {
  return {
    meta: { filters },
    queryFn: ({ signal }) => {
      const { labels, projects, range, ...rest } = filters;
      const params: Record<string, any> = {
        dateFrom: range.start.toISO(),
        dateTo: range.end.toISO(),
        includeTimers: true,
        ...rest,
      };

      if (projects) {
        params.projects = projects;
      }

      if (labels) {
        params.labels = labels;
      }

      return workspaceUuid
        ? fetch<Worklog[]>({
            method: 'GET',
            signal,
            url: `/workspaces/${workspaceUuid}/worklogs?${qs.stringify(params)}`,
          }).then((data) =>
            data?.map((w) => ({
              ...w,
              startedAt: fromIso(w.startedAt),
              timerTriggeredAt: fromIso(w.timerTriggeredAt),
            })),
          )
        : Promise.resolve([] as Worklog[]);
    },
    queryKey: [workspaceUuid, WORKLOGS_STORE_KEY, rangeKey(filters.range)],
    refetchInterval: refetchInterval || false,
  };
};

export const countWorkLogs = (
  workspaceUuid: ID | undefined,
  filters: WorklogFilters,
): UseQueryOptions<any, Error, number> => {
  return {
    meta: { filters },
    queryFn: () => {
      const { labels, projects, range, ...rest } = filters;
      const params: Record<string, any> = {
        dateFrom: range.start.toISO(),
        dateTo: range.end.toISO(),
        ...rest,
      };

      if (projects) {
        params.projects = projects;
      }

      if (labels) {
        params.labels = labels;
      }

      return workspaceUuid
        ? fetch<{ totalTimeSpent: number }>({
            method: 'GET',
            url: `/workspaces/${workspaceUuid}/worklogs/count?${qs.stringify(params)}`,
          }).then((data) => data?.totalTimeSpent || 0)
        : Promise.resolve(undefined);
    },
    queryKey: [workspaceUuid, WORKLOGS_COUNT_STORE_KEY],
  };
};

export const exportWorkLogs = (
  workspaceUuid: ID | undefined,
  setLoading?: (isLoading: boolean) => void,
): UseMutationOptions<any, Error, ['csv' | 'pdf', WorklogFilters]> => {
  return {
    mutationFn: ([type, filters]) => {
      const { labels, projects, range, ...rest } = filters;
      const params: Record<string, any> = {
        dateFrom: range.start.startOf('day').toISO(),
        dateTo: range.end.startOf('day').toISO(),
        labels: [],
        projects: [],
        type,
        ...rest,
      };

      if (projects) {
        params.projects = projects;
      }

      if (labels) {
        params.labels = labels;
      }

      if (!workspaceUuid) {
        return Promise.resolve(undefined);
      }

      setLoading?.(true);

      const p = fetch<{ totalTimeSpent: number }>({
        method: 'GET',
        url: `/workspaces/${workspaceUuid}/worklogs/export?${qs.stringify(params)}`,
      })
        .then((data) => data?.totalTimeSpent || 0)
        .finally(() => {
          setLoading?.(false);
        });

      toast.promise(
        p,
        {
          error: t('Cannot export Worklogs.'),
          pending: t('Exporting...'),
          success: t('Worklogs exported.'),
        },
        { toastId: 'exportWorklogs' },
      );

      return p;
    },
    mutationKey: [workspaceUuid, WORKLOGS_EXPORT_STORE_KEY],
  };
};

export const saveWorklog = (
  workspaceUuid: string | undefined,
): UseMutationOptions<
  Worklog | undefined,
  Error,
  CreateVariables<WorklogRequest> | UpdateVariables<WorklogRequest>
> => ({
  mutationFn: (variables: CreateVariables<WorklogRequest> | UpdateVariables<WorklogRequest>) => {
    const { labels, project } = variables;
    setWorklogPrefill({ labels, project });

    if (!workspaceUuid) {
      throw new Error('Missing Workspace');
    }

    const x = fetch<Worklog | undefined>({
      body: { ...variables, uuid: undefined },
      method: 'uuid' in variables && variables.uuid ? 'PUT' : 'POST',
      url:
        'uuid' in variables && variables.uuid
          ? `/workspaces/${workspaceUuid}/worklogs/${variables.uuid}`
          : `/workspaces/${workspaceUuid}/worklogs`,
    }).then((res) =>
      res
        ? {
            ...res,
            startedAt: fromIso(res.startedAt)!,
            timerTriggeredAt: fromIso(res.timerTriggeredAt),
          }
        : undefined,
    );

    return x;
  },
  mutationKey: [workspaceUuid, WORKLOGS_STORE_KEY, 'save'],
  onSuccess: onSuccess<Worklog, WorklogRequest>([workspaceUuid, WORKLOGS_STORE_KEY], true),
});

export const deleteWorklog = (workspaceUuid?: string) => ({
  mutationFn: (uuid: DeleteVariables) => {
    if (!workspaceUuid) {
      throw new Error('Missing Workspace');
    }

    const p = fetch<Worklog>({
      method: 'DELETE',
      url: `/workspaces/${workspaceUuid}/worklogs/${uuid}`,
    });

    toast.promise(
      p,
      {
        error: t('Cannot remove Worklog.'),
        pending: t('Removing Worklog...'),
        success: t('Worklog deleted.'),
      },
      { toastId: 'playWorklog' },
    );

    return p.catch(() => undefined);
  },
  mutationKey: [workspaceUuid, WORKLOGS_STORE_KEY, 'deleteWorklog'],
  onSuccess: onSuccess<Worklog, DeleteVariables>([workspaceUuid, WORKLOGS_STORE_KEY], true),
});

export const useWorklogActions = (workspace: ID | undefined) => {
  const save = useMutation(saveWorklog(workspace));
  const remove = useMutation(deleteWorklog(workspace));

  return useMemo(
    () => ({
      finish: (w: Worklog) => {
        let timeSpent = w.timeSpent || 0;
        if (w.status === 'running' && w.timerTriggeredAt) {
          timeSpent += (w.timerTriggeredAt.diffNow().as('minutes') * -1) | 0;
        }

        return save
          .mutateAsync({
            status: 'finished',
            timeSpent: Math.max(timeSpent, 1),
            timerTriggeredAt: null,
            uuid: w.uuid,
          })
          .catch(() => {});
      },
      pause(w: Worklog) {
        const p = save.mutateAsync({
          status: 'paused',
          timeSpent:
            (w.timeSpent || 0) +
            (((DateTime.now().toMillis() - (w.timerTriggeredAt || DateTime.now()).toMillis()) /
              60000) |
              0),
          timerTriggeredAt: null,
          uuid: w.uuid,
        });

        toast.promise(
          p,
          {
            error: t('Cannot pause Worklog.'),
            pending: t('Pausing...'),
            success: t('Worklog paused.'),
          },
          { toastId: 'playWorklog' },
        );

        return p.catch(() => {});
      },
      play(w: Worklog) {
        const p = save.mutateAsync({
          startedAt: DateTime.now().startOf('day'),
          status: 'running',
          timerTriggeredAt: iso(DateTime.now()),
          uuid: w.uuid,
        });

        toast.promise(
          p,
          {
            error: t('Cannot start Worklog.'),
            pending: t('Starting...'),
            success: t('Worklog started.'),
          },
          { toastId: 'playWorklog' },
        );

        return p.catch(() => {});
      },
      remove(uuid: ID) {
        const p = remove.mutateAsync(uuid).catch(() => {});

        return p;
      },

      removeIntegrationLog(uuid: ID, key: string) {
        const p = save.mutateAsync({ integrationLogs: { itemId: null, key }, uuid });

        toast.promise(
          p,
          {
            error: t('Cannot delete Worklog Integration Record.'),
            pending: t('Deleting integration log...'),
            success: t('Worklog Integration Log Deleted.'),
          },
          { toastId: 'removeIntegrationLog' },
        );

        return p;
      },

      save: (uuid: undefined | ID, d: WorklogValidFormData) => {
        const p = save.mutateAsync({
          description: d.description || '',
          integrationLogs:
            d.integrationLogs && d.integrationLogs.key
              ? { ...d.integrationLogs, key: d.integrationLogs.key! }
              : undefined,
          labels: d.labels || [],
          project: d.project,
          startedAt: DateTime.fromJSDate(d.status === 'running' ? new Date() : d.startedAt).startOf(
            'day',
          ),
          status: d.status as WorklogStatus,
          timeSpent: d.timeSpent,

          timerTriggeredAt: d.timerTriggeredAt
            ? DateTime.fromJSDate(d.timerTriggeredAt)
            : d.timerTriggeredAt === null
            ? null
            : undefined,
          // @ts-ignore TODO:
          uuid,
        });

        toast.promise(
          p,
          {
            error: t('Cannot save Worklog.'),
            pending: t('Saving...'),
            success: t('Worklog saved.'),
          },
          { toastId: 'saveWorklog' },
        );

        return p;
      },

      setLabels: (uuid: ID, labels: ID[]) => {
        const p = save.mutateAsync({ labels: Array.from(new Set(labels)), uuid });

        toast.promise(
          p,
          {
            error: t('Cannot update Worklog Labels.'),
            pending: t('Saving...'),
            success: t('Worklog Labels updated.'),
          },
          { toastId: 'setWorklogLabel' },
        );

        return p.catch(() => {});
      },
      setProject: (uuid: ID, project: ID) => {
        const p = save.mutateAsync({ project, uuid });

        toast.promise(
          p,
          {
            error: t('Cannot update Worklog Project.'),
            pending: t('Saving...'),
            success: t('Worklog Project updated.'),
          },
          { toastId: 'setWorklogProject' },
        );

        return p.catch(() => {});
      },

      setSpent: (uuid: ID, timeSpent: number, isRunning?: boolean) => {
        const patch: Partial<WorklogRequest> & { uuid: ID } = { timeSpent, uuid };

        if (isRunning) {
          patch.timeSpent = timeSpent;
          patch.timerTriggeredAt = DateTime.now();
        }

        const p = save.mutateAsync(patch);

        toast.promise(
          p,
          {
            error: t('Time spent was not updated.'),
            pending: t('Updating time spent.'),
            success: t('Time spent updated.'),
          },
          { toastId: `UPDATE_SPENT_${uuid}` },
        );

        return p.catch(() => {});
      },
      setStartedAt: (uuid: ID, startedAt: DateTime) => {
        const p = save.mutateAsync({ startedAt: iso(startedAt)!, uuid });

        toast.promise(
          p,
          {
            error: t('Cannot update Worklog.'),
            pending: t('Saving...'),
            success: t('Worklog updated.'),
          },
          { toastId: `UPDATE_DATE_${uuid}` },
        );

        return p.catch(() => {});
      },
    }),
    [remove, save],
  );
};

export function iso(date: DateTime | null | undefined) {
  return date ? (date.toISO() as unknown as DateTime) : date;
}

export function fromIso(date: DateTime | null | undefined) {
  if (!date) {
    return date;
  }
  return DateTime.fromISO(date as unknown as string);
}

export function rangeKey(range?: Range) {
  if (!range) {
    return '';
  }
  return `range-${range.start.toISO()}-${range.end.toISO()}`;
}
