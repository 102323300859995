import classNames from 'classnames';
import { FC } from 'react';
import {
  ClearIndicatorProps,
  ControlProps,
  DropdownIndicatorProps,
  IndicatorsContainerProps,
  InputProps,
  LoadingIndicatorProps,
  MultiValueRemoveProps,
  components,
} from 'react-select';

import Icon from 'ui/components/Icon';

import IconButton from '../buttons/IconButton';

import { Option } from './props';

export const ClearIndicator: FC<ClearIndicatorProps<Option<any>, any>> = (props) => (
  <div className="absolute right-7">
    <IconButton
      className={classNames(
        props.className,
        'relative text-neutral-400 invisible group-hover:visible right-0',
      )}
      name="clear"
      size="sm"
      variant="text"
      onClick={(e) => {
        e.preventDefault();
        props.clearValue();
      }}
    />
  </div>
);

export const DropdownIndicator: FC<DropdownIndicatorProps<Option<any>>> = ({ innerProps }) => {
  return (
    // @ts-ignore TODO: omit inner props ?
    <IconButton
      className="text-neutral-500 my-0 self-start"
      name="arrow_drop_down"
      size="sm"
      variant="text"
      {...innerProps}
      onClick={(e) => {
        innerProps.onClick?.(e as any);
      }}
    />
  );
};

export const Control: FC<ControlProps<Option<any>, false>> = ({
  children,
  className,
  ...props
}) => (
  <div {...props} className={className}>
    {children}
  </div>
);

export const LoadingIndicator: FC<LoadingIndicatorProps<Option<any>>> = () => {
  return <Icon className="mx-2" name="loading" size={16} />;
};

export const MultiValueRemove: FC<MultiValueRemoveProps<Option<any>>> = ({ innerProps }) => (
  <Icon
    className="text-neutral-400 group-hover/chip:text-neutral-700 ml-1"
    name="clear"
    onClick={(e) => {
      e.preventDefault();
      // @ts-ignore TODO: ...
      innerProps.onClick?.(e);
    }}
  />
);

export const IndicatorSeparator = () => null;

export const I = components.Input;

export const Input: FC<InputProps<Option<any>>> = (props) => (
  <I
    {...props}
    onKeyDown={(e) => {
      if (e.key === 'Escape') {
        e.stopPropagation();
        // @ts-ignore TODO: ...
        props.onBlur();
      } else {
        // @ts-ignore TODO: ...
        props.onKeyDown?.(e);
      }
    }}
  />
);

export const IndicatorsContainer: FC<IndicatorsContainerProps<Option<any>>> = ({ children }) => (
  <div className="flex relative items-center gap-1 pr-1 mt-0.5 self-start">{children}</div>
);

export const defaultComponents = {
  ClearIndicator,
  // Control,
  DropdownIndicator,
  IndicatorSeparator,
  IndicatorsContainer,
  Input,
  LoadingIndicator,
  MultiValueRemove,
};
