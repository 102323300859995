import { fetch } from 'api/fetch';
import { Profile } from 'api/models';
import { createResizedImage } from 'app/helpers/imageResizer';

export const imageUpload = (url: string, file: File | null) => {
  return resize(file).then((file) => {
    const body = new FormData();

    if (file) {
      body.append('file', file);
    }

    return fetch<Profile>({
      body: file ? body : undefined,
      headers: { 'content-type': 'multipart/form-data' },
      method: file ? 'PUT' : 'DELETE',
      url,
    });
  });
};

function resize(file: null | File, width: number = 96, height: number = 96): Promise<null | File> {
  if (!file) {
    return Promise.resolve(null);
  }
  return new Promise<File>((resolve, reject) => {
    try {
      createResizedImage(
        file, // Is the file of the image which will resized.
        width, // Is the maxWidth of the resized new image.
        height, // Is the maxHeight of the resized new image.
        'PNG', // Is the compressFormat of the resized new image.
        100, // Is the quality of the resized new image.
        0, // Is the degree of clockwise rotation to apply to uploaded image.
        (file) => resolve(file as File), // Is the callBack function of the resized new image URI.
        'file', // Is the output type of the resized new image.
        10, // Is the minWidth of the resized new image.
        10, // Is the minHeight of the resized new image.
      );
    } catch (e) {
      reject(e);
    }
  });
}
