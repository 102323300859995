import { FC, useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import Field from 'ui/components/Field';

import Props from './props';

export type { Props };

const TextField: FC<Props> = ({
  autoComplete,
  autoFocus,
  disabled,
  id,
  multiline,
  name,
  onBlur,
  onChange,
  onFocus,
  readOnly,
  required,
  rows = 2,
  type,
  value,
  ...props
}) => {
  const htmlId = id || `text-field-${name || Math.random().toFixed(10)}`;
  const textareaRef = useRef(null);

  return (
    <Field
      {...props}
      disabled={disabled}
      htmlFor={htmlId}
      required={required}
      onClear={value ? () => onChange(null) : undefined}
    >
      {multiline ? (
        <TextareaAutosize
          ref={textareaRef}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          className="leading-tight text-base flex-1 min-h-[3.5rem] mt-2"
          disabled={disabled}
          maxRows={10}
          minRows={1}
          readOnly={readOnly}
          rows={rows}
          value={value || ''}
          onBlur={onBlur}
          onChange={(e) => onChange(e.target.value || null)}
          onFocus={onFocus}
        />
      ) : (
        <input
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          className="leading-8 py-[1px] text-base flex-1 truncate min-w-[3.5rem]"
          disabled={disabled}
          id={htmlId}
          name={name}
          readOnly={readOnly}
          required={required}
          size={10}
          type={type}
          value={value || ''}
          onBlur={onBlur}
          onChange={(e) => onChange(e.target.value || null)}
          onFocus={onFocus}
        />
      )}
    </Field>
  );
};

export default TextField;
