import { QueryClientProvider } from '@tanstack/react-query';
import { FC, memo, PropsWithChildren } from 'react';

import { getClient } from './getClient';

const ApiProvider: FC<PropsWithChildren> = memo(({ children }) => {
  return <QueryClientProvider client={getClient()}>{children}</QueryClientProvider>;
});

export default ApiProvider;
